import parseMobile from 'libphonenumber-js/mobile'
import select2 from 'select2'
import 'select2/dist/css/select2.css'
import 'jquery-mask-plugin'
import 'mailcheck'
import '@client-side-validations/client-side-validations'
import '@client-side-validations/simple-form'
import '@client-side-validations/simple-form/dist/simple-form.bootstrap4'
import 'bootstrap-datepicker'
import 'selectize'

$(document).ready(function(){

  $('#profile_date_of_birth').on('change focusout', function(){
    var input     = $('#profile_date_of_birth');
    var form      = input.closest('form');
    var validators = form[0].ClientSideValidations.settings.validators;
    input.isValid(validators);
  });

  var domains = ['gmail.com', 'aol.com', 'outlook.com', 'yahoo.com'];
  var secondLevelDomains = ['hotmail']
  var topLevelDomains = ["com", "net", "org"];

  $('#account_email').on('blur', function() {
    $(this).mailcheck({
      domains: domains,
      secondLevelDomains: secondLevelDomains,
      topLevelDomains: topLevelDomains,
      suggested: function(element, suggestion) {
      // callback code
        if ($("#email_suggestion").length > 0) {
          $("#email_suggestion").replaceWith("<small id='email_suggestion' class='text-danger form-text'>Did you mean " + suggestion.full + "?</small>");
        } else {
          element.parent().append("<small id='email_suggestion' class='text-danger form-text'>Did you mean " + suggestion.full + "?</small>");
        }
      },
    empty: function(element) {
      // callback code
      $('#email_suggestion').hide();
    }
  });
  });

  $('#profile_phone_mobile').on('blur', function(){
    var element = this;
    var phoneNumber = parseMobile(element.value, 'AU');
    if (phoneNumber.isPossible() === false) {
      $(element).addClass('is-invalid');
      if ($('#mobile_error').length === 0) {
        $(element).parent().append("<div id='mobile_error' class='invalid-feedback'>Not a valid mobile number</div>");
      }
     } else {
      $(element).removeClass('is-invalid');
      $(element).parent().remove('.is-invalid');
    }
  });
  $('[data-toggle="select2"]').select2();


  $('.suburb-list').selectize({
    valueField: "id",
    labelField: "text",
    searchField: "text",
    create: false,
    load: function(query, callback) {
      if (!query.length) return callback();
      $.ajax({
        url: "/tools/suburb",
        type: "GET",
        dataType: "json",
        data: {
          q: query
        },
        error: function () {
          callback();
        },
        success: function(res) {
          callback(res.results);
        }
      });
    }
  });

  $.applyDataMask();

});
